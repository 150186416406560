@import url("https://fonts.googleapis.com/css?family=Rubik&display=swap");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #6f7f9e;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.body {
  min-height: 80vh;
  margin: 0;
  background-image: linear-gradient(rgba(7, 7, 7, 0.889), rgba(0, 0, 0, 0.614)),
    url("./imc.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  font-family: "Rubik";

  /* width: 100%; */
}

#imgconts {
  display: flex;
  align-items: center;
  margin-bottom: 22px;
  margin-left: -12px;
}

.usersdatas {
  position: relative;
  right: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  /* background-color: white; */
}

.usersdata {
  position: relative;
  right: 0;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 9px;
  /* background-color: white; */
}

.img_conts {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img_conts img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.img_cont img {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.163);
}

.img_conto img {
  width: 165px;
  height: 165px;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.163);
}

.one,
.two {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: wheat; */
}

.videoo {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 88;
  left: 0px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cards {
  width: 70%;
  height: 100%;
  height: 550px;
  border-radius: 15px !important;
  background-color: rgba(255, 255, 255, 0.09) !important;
  backdrop-filter: blur(9px);
  /* border: 2px solid rgb(102, 102, 102); */
  display: flex;
  /* justify-content: space-between; */
  flex-direction: column;
  padding-left: 9px;
  padding-right: 9px;
  padding-top: 12px;
}

.cardso {
  width: 90%;
  height: 100%;
  height: 570px;
  border-radius: 15px !important;
  background-color: rgba(255, 255, 255, 0.056) !important;
  backdrop-filter: blur(9px);
  border: 2px solid rgba(255, 255, 255, 0.047);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: 9px;
  padding-right: 9px;
  padding-top: 12px;
}

.contacts_body {
  padding: 0.75rem 0 !important;
  overflow: scroll;
  white-space: nowrap;
}

.msg_card_body {
  overflow: scroll;
  padding-left: 12px;
  padding-right: 13px;
  /* background: blue; */
  height: 74%;
  overflow: auto;
  /* background-color: white; */
}

.card-header {
  border-radius: 15px 15px 0 0 !important;
  border-bottom: 0 !important;
}

.card-footer {
  width: 100%;
  border-radius: 0 0 15px 15px !important;
  border-top: 0 !important;
}

.container {
  align-content: center;
}

.search {
  width: 100%;
  border-radius: 15px 0 0 15px !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
}

.search:focus {
  box-shadow: none !important;
  outline: 0px !important;
}

.type_msg {
  background-color: rgba(0, 0, 0, 0.912) !important;
  border-radius: 8px;

  color: rgb(255, 255, 255) !important;
  height: 40px !important;
  overflow-y: auto;
  padding-left: 12px;

  font-size: 0.7em;
  padding-right: 36px;
  border: 1px solid rgba(255, 255, 255, 0.258) !important;
}

.type_msg:focus {
  box-shadow: none !important;
  outline: 0px !important;
}

.attach_btn {
  border-radius: 15px 0 0 15px !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
  cursor: pointer;
}

.send_btn {
  border-radius: 0 15px 15px 0 !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
  cursor: pointer;
}

/* #myimg{
	width: 30px;
	height: 30px;
	border-radius: 50%;
} */
.search_btn {
  border-radius: 0 15px 15px 0 !important;
  background-color: rgba(0, 0, 0, 0.125) !important;
  border: 0 !important;
  color: white !important;
  cursor: pointer;
}

.contacts {
  list-style: none;
  padding: 0;
}

.contacts li {
  width: 100% !important;
  padding: 5px 10px;
  margin-bottom: 15px !important;
}

.active {
  background-color: rgba(0, 0, 0, 0.3);
}

.user_img {
  height: 70px;
  width: 70px;
  border: 1.5px solid #f5f6fa;
}

.user_img_msg {
  height: 40px;
  width: 40px;
  /* border: 1.5px solid #f5f6fa; */
}

.img_cont {
  position: relative;
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.086);
}

.lauc {
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img_cont_msg {
  height: 35px;
  width: 35px;
}

.online_icon {
  position: absolute;
  height: 15px;
  width: 15px;
  background-color: #ff3131;
  border-radius: 50%;
  bottom: 0.2em;
  right: 0.4em;
  border: 1.5px solid white;
}

.offline {
  background-color: #c23616 !important;
}

.user_info {
  /* background-color: white; */
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 23px;
  margin-top: 12px;
}

.user_info span {
  font-size: 12px;
  color: white;
}

.user_info p {
  font-size: 0.99em;
  color: rgba(255, 255, 255, 0.6);
}

.video_cam {
  margin-left: 50px;
  margin-top: 5px;
}

.video_cam span {
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin-right: 20px;
}

.msg_cotainer {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  border-radius: 25px;
  background-color: rgb(255, 255, 255);
  padding: 12px;
  position: relative;
  color: rgb(0, 0, 0);
  font-size: 0.7em;
  max-width: 80%;
}

.msg_cotainer_send {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  border-radius: 25px;
  background-color: #d60000;
  padding: 10px;
  position: relative;
  color: rgb(255, 255, 255);
  font-size: 0.7em;
}

.msg_time {
  position: absolute;
  left: 0;
  bottom: -15px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 10px;
}

.msg_time_send {
  position: absolute;
  right: 0;
  bottom: -15px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.7em;
}

.msg_head {
  position: relative;
}

#action_menu_btn {
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
  cursor: pointer;
  font-size: 20px;
}

.action_menu {
  z-index: 1;
  position: absolute;
  padding: 15px 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 15px;
  top: 30px;
  right: 15px;
  display: none;
}

.action_menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.action_menu ul li {
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 5px;
}

.action_menu ul li i {
  padding-right: 10px;
}

.action_menu ul li:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
}

.contacts_body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.contacts_body::-webkit-scrollbar {
  display: none;
}

.msg_card_body {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: scroll;
  padding-left: 12px;
  padding-right: 13px;
  /* background: blue; */
  height: 62%;
  overflow: auto;
  /* background-color: white; */
}

.msg_card_body::-webkit-scrollbar {
  display: none;
}

input::placeholder {
  font-size: 0.89em;
  color: rgba(255, 255, 255, 0.663);
}

.three {
  width: 100%;
  padding: 0;
  /* background-color: rgba(0, 6, 96, 0.147); */
}

.four {
  display: flex;
  padding-bottom: 5px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom: 1px solid rgba(255, 255, 255, 0.073);
}

.below {
  width: 90%;
}

form {
  width: 100%;
}

input {
  width: 100%;
}

#welc {
  font-size: 0.7em;
}
#messageArea {
  position: relative;
  background: rgba(255, 255, 255, 0);
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  margin-left: 5px;
  justify-content: center;
  margin-left: 23px;
}

.mybuton {
  position: absolute;
  /* background-color: rgba(255, 255, 255, 0.715); */
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 5px;
}

.mybuton img {
  width: 20px;
}

#messageFormeight {
  /* background-color: wheat; */
  /* width: 96%; */
  /* min-height: 53vh; */
  /* position: relative; */
  /* margin-left: -12px; */
  margin-top: 23px;
}

.below {
  position: absolute;
  bottom: 6px;
}

@media screen and (max-width: 640px) {
  .contacts_card {
    margin-bottom: 15px !important;
  }

  .card {
    position: absolute;
    width: 100%;
    min-height: 100vh;
  }

  .msg_card_body {
    width: 100%;
    overflow-y: none;
    /* min-height: 100vh; */
    height: 69%;
  }

  input::placeholder {
    font-size: 0.99em;
    color: rgba(255, 255, 255, 0.479);
  }

  .three {
    /* width: 100%; */
    /* padding: 23px; */
    /* background-color: rgba(0, 6, 96, 0.147); */
  }

  .four {
    display: flex;
  }

  .below {
    width: 100%;
  }

  form {
    width: 100%;
  }

  input {
    width: 80%;
  }

  .cards {
    width: 90%;
    height: 82vh;
    position: relative;
    top: -11px;
  }

  .below {
    position: absolute;
    bottom: 6px;
  }

  #messageArea {
    position: relative;
    width: 94%;
    /* background: white; */
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    margin-left: 0px;
  }

  .user_info {
    /* background-color: white; */
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 0px;
    margin: 12px;
  }

  input {
    width: 100%;
  }

  #messageFormeight {
    /* background-color: wheat; */
    width: 96%;
    min-height: 53vh;
    position: relative;
    /* margin-left: -12px; */
    margin-top: 23px;
  }
}
